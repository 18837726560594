import {QbElement} from "@qb-assets-js/qb-elements/qb-element";
import markdownit from '@node/markdown-it';
import TurndownService from '@node/turndown';


function render_md(input) {
    input = input.replace(/^\s+|]s+$/g, "");
    if(/(^|\n)(#{1,6}\s|>\s|(\d+\.|\*|-|\+)\s|\n{2,})/.test(input)) {
        const MD = markdownit();
        MD.block.ruler.disable('heading');
        return MD.render(input);
    }
    else {
        const rv = markdownit({breaks: true}).renderInline(input);
        return rv.replace(/(<br>\n)+/, m=>m.split(/\n/).filter(n=>n).slice(1).join(""));
    }
}

export class QbGettext extends QbElement {
    // static shadow = {mode: 'open', sheets: ["qb-gettext", "locale-flags"]};
    static shadow = {mode: 'open'};
    static template = "<slot part='slot'></slot>";
    static observedAttributes = ["name","edit"]
    $name(value) {}
    $edit(value) {
        console.log("edit", value);
    }

    get nodes() {
        return [...this.children].filter(n=>n.lang);
    }

    set multi(_) {}
    get reorder() {
        const langs = document.preferredLang,
            index = l => (langs.indexOf(l) + 1) || 1000,
            nodes = this.nodes.sort((l, h) => {
                const cmp = index(l.lang) - index(h.lang);
                if(cmp) return cmp;
                const lo = l.textContent.trim().toUpperCase(), hi = h.textContent.trim().toUpperCase();
                if (lo < hi) return -1;
                if (lo > hi) return 1;
                if(l.isConnected) h.remove();
                else if(h.isConnected) l.remove();
                return 0;
            }).filter(n=>n.isConnected);
        this.prepend(...nodes);
        // this.textContent = "";
        // if(nodes[0]) this.append(nodes[0].cloneNode(true));
    }

    get init() {
        if(this.children.length) return;
        for(const n of this.childNodes) {
            const data = n.jsonData;
            if(data) return this.content = data;
        }
        // if(this.hasChildNodes) {
        //     this.content = this.textContent;
        // }
    }

    // reprocess() {
    //     ["_", "n_", "p_"].forEach(k => {
    //         if(!this.#content.hasOwnProperty(k)) return;
    //         const v = this.#content[k];
    //         let args;
    //         if(k === "_") args = [i18n?.lang, window[k](v)];
    //         else if(/^[np]_$/.test(k)) args = [i18n?.lang, window[k](...v)];
    //         this.language(...args);
    //     });
    //     this.reorder;
    // }

    get value() {
        const contents = [...this.querySelectorAll("[lang]")].map(n=>
            [n.lang, n.innerHTML.replace(/<qb-mixin key="([^"]+)"><\/qb-mixin>/g, "%{$1}")]
        );
        return this.multi ? contents : Object.fromEntries(contents);
    }


    set value(value) {
        this.nodes.forEach(n => n.remove());
        this.textContent = "";
        if(value === null || value === undefined) return;
        if(typeof value === 'string') value = {"$": value};
        this.update(value);
    }

    set md(value) {
        this.nodes.forEach(n => n.remove());
        this.textContent = "";
        if(value === null || value === undefined) return;
        if(typeof value === 'string') value = {"$": value};
        if(!(value instanceof Array)) value = Object.entries(value);
        this.update(value.map(([k, v]) => [k, render_md(v)]));
    }

    get md() {
        const turndownService = new TurndownService();
        const contents = [...this.querySelectorAll("[lang]")].map(n=>{
            const html = n.innerHTML.replace(/<qb-mixin key="([^"]+)"><\/qb-mixin>/g, "%{$1}");
            return [n.lang, turndownService.turndown(html)];
        });
        return this.multi ? contents : Object.fromEntries(contents);
    }

    set content(value) { this.value = value }
    get content() { return this.value }

    get _placeholder() { return this.getAttribute("_placeholder") }
    set _placeholder(value) { value ? this.setAttribute("_placeholder", value) : this.removeAttribute("_placeholder") }

    get multi() { return this.hasAttribute("multi") }
    set multi(value) { value ? this.setAttribute("multi", "") : this.removeAttribute("multi") }



    update(value) {
        if(!(value instanceof Array)) value = Object.entries(value);
        value.forEach(([k, v]) => {
            let args;
            if(/boolean|string|number/.test(typeof v)) {
                v = v.toString();
                if(k === "") args = ["$", v]; // FIXME remove after fixing agnostic to key $
                else if(k === "$") args = ["$", v];
                else if(k === "_") args = [i18n?.lang, window[k](v)];
                else if(k.length === 2) args = [k, v];
                else return;
            }
            else if(v instanceof Array) {
                if(/^[np]_$/.test(k)) args = [i18n?.lang, window[k](...v)];
            }
            else return;

            if(args?.length) this.#language(...args);
        });
        this.reorder;
    }

    language(lang, text) {
        this.#language(lang, text);
        this.reorder;
    }

    #language(lang, text) {
        if(lang === undefined) lang = "_";
        if(!/^([a-z]{2}|\$|_)$/.test(lang)) return;
        // const fragment = document.createElement('div');
        // fragment.innerHTML = text || "";
        // fragment.querySelectorAll(":not(hr, img, span[class]):empty").forEach(n => n.remove());
        // text = fragment.innerHTML.trim();
        if(!text) return this.querySelector(`[lang="${lang}"]`)?.remove();
        const node = this.#node(lang || "$", text);
        node.innerHTML = text.replace(/%\{([^}]+)}/g, (m0, m1) => this.#mixin(m1));
        if(!node.isConnected) this.append(node);
        return text;
    }

    #node(lang, text) {
        const id = (lang === "$" ? "XX" : lang) + text.toUpperCase().hashCode,
            selector = this.multi ? `#${id}[lang="${lang}"]` : `[lang="${lang}"]`;
        const node = this.querySelector(selector);
        if(node) return node;
        const events = this.name ? [this, {click: "edit"}] : undefined;
        return SPAN({lang, id, events});
    }

    set #counterIncrement(key) {
        const increments = new Set([...this.style.counterIncrement.matchAll(/([a-z_-]+) \d+/g)].map(m=>m[1])).add(key);
        this.style.counterIncrement = [...increments].join(" ");
    }

    #mixin(key) {
        const m = key.match(/^increment-(([a-z][a-z0-9_-]+)\s*(\S+)?)/);
        if(m) {
            key = `counter-${m[1]}`;
            this.#counterIncrement = m[2];
        }
        return Object.assign(document.createElement("qb-mixin"), {key}).outerHTML;
    }


    get form() { return this.getAttribute("form") }

    get name() { return this.getAttribute('name') }
    set name(value) {
        this.setAttribute('name', value);
    }

    #editTo;

    set edit(value) {
        if(value === false) this.querySelectorAll('.edit').forEach(n => n.classList.remove('edit'));
        else this.#node(value).classList.add('edit');
        clearTimeout(this.#editTo);
        this.#editTo = setTimeout(() => {
            if(this.querySelector('.edit')) this.classList.add('edit');
            else this.classList.remove('edit');
        }, 100);
    }

    handleEvent_edit(evt) {
        console.log("qb-gettext edit", evt);
    }

}

QbGettext.register;
self.QB_GETTEXT = (...args) => cT('qb-gettext', ...args);
